import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/posts/List';
import Hashtag from '../components/Hashtag';

const CategoryPage = ({ data, pageContext }) => {
  const { allAirtable: { edges } } = data;

  return (
    <Layout pageClass="page--full">
      <SEO
        keywords={ [] }
        title={ pageContext.category }
      />
      <Hashtag currentPage={ pageContext.category.toLowerCase() } />
      <PostList posts={ edges } />
    </Layout>
  );
};

export default CategoryPage;

export const query = graphql`
  query($category: String!) {
    allAirtable(
      filter: {table: {eq: "Posts"}, data: {Category: {eq: $category}}}, 
      sort: {fields: data___Date, order: DESC}
    ) {
      edges {
        node {
          id
          data {
            Title
            Category
            Author {
              data {
                Name
                Picture {
                  localFiles {
                    childImageSharp {
                      fixed(width: 80) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                Passion
                Specialism
              }
            }
            Date(formatString: "DD.MM.YYYY")
            Thumbnail {
              localFiles {
                extension
                url
                childImageSharp {
                  fluid(maxHeight: 237) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
